
@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    position: relative;
}

.buttonLeft,
.buttonRight {
    position: absolute;
    top: 40%;
    z-index: 1;
    width: var(--sizing-core-16);
    height: var(--sizing-core-16);
    box-shadow: 0 0 1px rgb(0 0 0 / 15%), 0 0 25px rgb(0 0 0 / 15%);
    transform: scale3d(0.9, 0.9, 0.9);
    
    &:hover {
        transform: scale3d(1, 1, 1);
    }

    @media (pointer: coarse) {
        display: none;
    }
}

.buttonLeft {
    left: 0;
    margin-left: calc(var(--spacing-core-8) * -1);
    margin-top: calc(var(--spacing-core-8) * -1);
    
}

.buttonRight {
    right: 0;
    margin-right: calc(var(--spacing-core-8) * -1);
    margin-top: calc(var(--spacing-core-8) * -1);
}

.carousel {
    --carousel-items: 1;
    --carousel-snap-align: start;    
    --carousel-gap: var(--spacing-core-4);

    scrollbar-width: none;

    @include mq.mq($from: small) {
        --carousel-snap-align: center;    
        --carousel-items: 1.5;
    }

    @include mq.mq($from: medium) {
        --carousel-snap-align: start;
        --carousel-items: 3;
    }

    @include mq.mq($from: large) {
        --carousel-items: 4;
        --carousel-gap: var(--spacing-core-6);
    }

    @include mq.mq($from: extra-large) {
        --carousel-items: 5;
    }
}